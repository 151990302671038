import * as React from "react";
import * as PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useState} from "react";
import {useCallback} from "react";
import Button from "react-bootstrap/Button";
import {useContext} from "react";
import ApiClient from "./ApiClient";
import Alert from "react-bootstrap/Alert";
import LoadingIndicator from "./LoadingIndicator";

function AddNameForm({ names, domainName, onAddition }) {
  const apiClient = useContext(ApiClient);
  const [name, setName] = useState("");
  const [nameservers, setNameservers] = useState("");
  const [nameserversValid, setNameserversValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const updateName = useCallback((e) => setName(e.target.value));
  const updateNameservers = useCallback((e) => {
    setNameservers(e.target.value);
    setNameserversValid(e.target.value.split('\n').filter(e => e).length >= 2);
  });

  const onSubmit = (e) => {
    e.preventDefault();

    const model = {
      name,
      nameservers: nameservers.split("\n"),
      expiration: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 7, // one week
    };

    setSubmitting(true);
    apiClient.post("/names", model)
        .then(r => {
          onAddition();
          setName("");
        }).catch(r => {
          setError(r.data.message)
    })
        .finally(() => setSubmitting(false));
  };

  return (
      <div id={"add-name-form"}>
        <h3>Add a Name</h3>

        <Form onSubmit={onSubmit}>
          <FormGroup as={Row} controlId={"name"}>
            <FormLabel column sm={3}>Name</FormLabel>
            <Col sm={9}>
              <InputGroup>
                <FormControl placeholder={"your-name"} value={name} onChange={updateName} required minLength={4} maxLength={32} />
                <InputGroup.Append>
                  <InputGroup.Text>.{ domainName }</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <Form.Text className="text-muted">
                Length of 4-32 characters, must start with a letter/number, and can then include dashes
              </Form.Text>
            </Col>
          </FormGroup>

          <FormGroup as={Row} controlId={"nameservers"}>
            <FormLabel column sm={3}>Nameservers</FormLabel>
            <Col sm={9}>
              <FormControl as="textarea" rows={"3"} value={nameservers} onChange={updateNameservers} />
              <Form.Text className="text-muted">
                At least two nameservers are required. Separate by new lines.
              </Form.Text>
            </Col>
          </FormGroup>

          <Row>
            <Col sm={{ offset: 3, span: 9}}>

              { error && (
                  <Alert variant={"danger"}><strong>Error:</strong> {error}</Alert>
              )}

              <Button variant={"primary"} type={"submit"} className={(nameserversValid && name) || submitting ? "" : "disabled"}>
                { submitting ? <LoadingIndicator text={"Creating..."} /> : "Add New Name" }
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
  );
}

AddNameForm.propTypes = {
  names: PropTypes.array.isRequired,
  domainName: PropTypes.string.isRequired,
  onAddition: PropTypes.func.isRequired,
};

export default AddNameForm;