import * as React from "react";
import * as PropTypes from "prop-types";

function LoadingIndicator({ text }) {
  return (
      <>
        { text }
      </>
  );
}

LoadingIndicator.propTypes = {};

export default LoadingIndicator;