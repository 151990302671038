import * as React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "./LoadingIndicator";
import {useContext} from "react";
import ApiClient from "./ApiClient";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import Button from "react-bootstrap/Button";
import {useCallback} from "react";

function YourNamesDisplay({ names, onDeletion }) {
  const apiClient = useContext(ApiClient);

  const deleteName = useCallback((id) => {
    apiClient.del(`/names/${id}`)
        .then(r => onDeletion());
  }, [apiClient, onDeletion]);

  return (
      <div id={"your-names"}>
        <h3>Your Names</h3>

        { names === null ? (
          <LoadingIndicator text={"Fetching your names..."} />
        ) :
            names.length === 0 ? (
                <p>You have no names yet! But, don't fret! You can create one below!</p>
              ): (
                  <Table striped size={"sm"}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Nameservers</th>
                        <th>Creation Date</th>
                        <th>Approx Expiration</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    { names.map(name => (
                        <tr key={name.id}>
                          <td>{ name.name }</td>
                          <td><ul className={"list-unstyled"}>{ name.nameservers.map(n => <li key={n}>{n}</li>) }</ul></td>
                          <td><Moment unix format="MMM D hh:mm a">{ name.creationDate }</Moment></td>
                          <td><Moment unix format="MMM D hh:mm a">{ name.ttl }</Moment></td>
                          <td><Button variant={"danger"} size={"sm"} onClick={() => deleteName(name.id)}>Delete</Button> </td>
                        </tr>
                    ))}
                    </tbody>
                  </Table>
              )
        }
      </div>
  );
}

YourNamesDisplay.propTypes = {
  names: PropTypes.array.isRequired,
  onDeletion: PropTypes.func.isRequired,
};

export default YourNamesDisplay;