import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import OauthClientFactory from 'vt-jwt-api-client';


async function startup() {
  const config = await makeRequest("/config.json");
  // const openIdConfiguration = await makeRequest(config['openidEndpoint']);
  const clientFactory = new OauthClientFactory({
    autoRedirect: true,
    redirectUri: window.location.origin,
    services: [
      {
        name: config.serviceName,
        apiHost: 'api.' + window.location.hostname,
        endpoints: {
          authorization_endpoint: config.oauth.authEndpoint,
          token_endpoint: config.oauth.tokenEndpoint
        }
      }
    ],
    clientId: config.serviceName
  });

  await clientFactory
      .bootstrap()
      .then(() => startReactApp(clientFactory, config))
      .catch(displayError);
}

// Support retry, since S3 sporadically fails delivering of services
async function makeRequest(url) {
  const configResponse = await fetch(url);
  if (Math.floor(configResponse.status / 100) !== 2)
    return new Promise((acc, rej) => {
      setTimeout(() => {
        makeRequest(url)
            .then(acc)
            .catch(rej);
      }, 1000);
    });
  else return configResponse.json();
}


function startReactApp(clientFactory, config) {
  ReactDOM.render(
      <React.StrictMode>
        <App oauthClientFactory={clientFactory} config={config}/>
      </React.StrictMode>,
      document.getElementById('root')
  );
}

function displayError(error) {
  if (error.type && error.type === 'ParentWindowStateNotFound') window.close();
  else if (error.authenticationRequired) return;

  ReactDOM.render(
      <ErrorDisplay error={error} />,
      document.getElementById('root')
  );
}

function ErrorDisplay({ error }) {
  return (
      <div className="error mt-5">
        An error has occurred!
        <br />
        Message: {error.message}
        <pre>{error.stack}</pre>
      </div>
  );
}


startup();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
