import * as React from "react";
import Navbar from "react-bootstrap/Navbar";

function Header({}) {
  return (
      <Navbar variant={"dark"} bg={"dark"} className={"mb-5"}>
        <Navbar.Brand href="#home">
          <img
              src="/vt-logo.svg"
              height="30"
              className="d-inline-block align-top mr-3"
              alt="VT Logo"
          />
          Name Issuer
        </Navbar.Brand>
      </Navbar>
  );
}

Header.propTypes = {};

export default Header;