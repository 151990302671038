import React from 'react';
import './theme.scss';
import Header from "./components/Header";
import Content from "./components/Content";
import ApiClient from "./components/ApiClient";

function App({ oauthClientFactory, config }) {
  const apiClient = React.useMemo(() => {
    return oauthClientFactory.getServiceEndpoint(config.serviceName);
  }, [oauthClientFactory, config]);

  return (
    <ApiClient.Provider value={apiClient}>
      <Header />
      <Content />
    </ApiClient.Provider>
  );
}

export default App;
