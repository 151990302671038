import * as React from "react";
import * as PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import YourNamesDisplay from "./YourNamesDisplay";
import AddNameForm from "./AddNameForm";
import {useContext} from "react";
import ApiClient from "./ApiClient";
import {useState} from "react";
import {useCallback} from "react";
import {useEffect} from "react";
import Alert from "react-bootstrap/Alert";

function Content({}) {
  const apiClient = useContext(ApiClient);
  const [names, setNames] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [domainName, setDomainName] = useState(null);

  const refreshNames = useCallback(() => {
    apiClient.get("/names").then((response) => setNames(response.data))
        .catch(() => setNameError("An error has occurred while trying to get your current names."));
  }, []);

  useEffect(() => { refreshNames(); }, []);
  useEffect(() => {
    apiClient.get("/config").then(r => setDomainName(r.data.domainName))
  }, []);

  return (
      <Container className={"mb-5"}>
        <Row>
          <Col sm={{ span: 10, offset: 1 }}>
            <Card>
              <Card.Body>
                <h2 className={"text-center mb-4"}>Demo Name Issuer</h2>

                <p>This application is intended to provide the ability to vend out temporary names for the use of demos/workshops. Names can be delegated to nameservers, where you can create your own subdomains, SSL certificates, or anything else you need.</p>
                <p>All names are intended to be temporary, as they can live up to a maximum length of one week. Once a name has expired, its delegation and record will be removed.</p>

                { names && (
                    <>
                      <hr />
                      <YourNamesDisplay names={names} onDeletion={refreshNames} />

                      <hr />
                      <AddNameForm names={names} domainName={domainName} onAddition={refreshNames} />
                    </>
                )}

                { nameError && (
                    <Alert variant={"danger"}>{ nameError }</Alert>
                )}

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
  );
}

Content.propTypes = {};

export default Content;